// @nebular theming framework
@import "~@nebular/theme/styles/theming";
// @nebular out of the box themes
@import "~@nebular/theme/styles/themes";

// Reduz o padding do container
nb-layout .layout .layout-container .content .columns nb-layout-column {
  padding: 0.25rem 0.25rem 0.75rem !important;
}

$nb-themes: nb-register-theme(
  (
    header-height: 2.5rem,
    layout-padding-top: 2.25rem,
    menu-item-icon-margin: 0 0.5rem 0 0,
    card-height-tiny: 13.5rem,
    card-height-small: 21.1875rem,
    card-height-medium: 28.875rem,
    card-height-large: 36.5625rem,
    card-height-giant: 44.25rem,
    card-margin-bottom: 1.875rem,
    card-header-with-select-padding-top: 0.5rem,
    card-header-with-select-padding-bottom: 0.5rem,
    select-min-width: 6rem,
    slide-out-background: #f7f9fc,
    slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
    slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
    form-control-color: #495057,
    // header-background-color: #6610f2,
    // header-text-color: #f7f9fc,
  ),
  default,
  default
);

$nb-themes: nb-register-theme(
  (
    header-height: 2.5rem,
    layout-padding-top: 2.25rem,
    menu-item-icon-margin: 0 0.5rem 0 0,
    card-height-tiny: 13.5rem,
    card-height-small: 21.1875rem,
    card-height-medium: 28.875rem,
    card-height-large: 36.5625rem,
    card-height-giant: 44.25rem,
    card-margin-bottom: 1.875rem,
    card-header-with-select-padding-top: 0.5rem,
    card-header-with-select-padding-bottom: 0.5rem,
    select-min-width: 6rem,
    slide-out-background: #252547,
    slide-out-shadow-color: 2px 0 3px #29157a,
    slide-out-shadow-color-rtl: -2px 0 3px #29157a,
    form-control-color: #495057,
  ),
  cosmic,
  cosmic
);

$nb-themes: nb-register-theme(
  (
    header-height: 2.5rem,
    layout-padding-top: 2.25rem,
    menu-item-icon-margin: 0 0.5rem 0 0,
    card-height-tiny: 13.5rem,
    card-height-small: 21.1875rem,
    card-height-medium: 28.875rem,
    card-height-large: 36.5625rem,
    card-height-giant: 44.25rem,
    card-margin-bottom: 1.875rem,
    card-header-with-select-padding-top: 0.5rem,
    card-header-with-select-padding-bottom: 0.5rem,
    select-min-width: 6rem,
    slide-out-background: linear-gradient(270deg, #edf1f7 0%, #e4e9f2 100%),
    slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
    slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
    form-control-color: #495057,
  ),
  corporate,
  corporate
);

$nb-themes: nb-register-theme(
  (
    header-height: 2.5rem,
    layout-padding-top: 2.25rem,
    menu-item-icon-margin: 0 0.5rem 0 0,
    card-height-tiny: 13.5rem,
    card-height-small: 21.1875rem,
    card-height-medium: 28.875rem,
    card-height-large: 36.5625rem,
    card-height-giant: 44.25rem,
    card-margin-bottom: 1.875rem,
    card-header-with-select-padding-top: 0.5rem,
    card-header-with-select-padding-bottom: 0.5rem,
    select-min-width: 6rem,
    slide-out-background: linear-gradient(270deg, #222b45 0%, #151a30 100%),
    slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
    slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
    form-control-color: #495057,
  ),
  dark,
  dark
);

@include nb-install-component() {
  // Cor do form e layout
  ::ng-deep .forms-all {
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-radius: 9px;
    // margin-left: 25px;
    // margin-right: 25px;
    background-color: nb-theme(border-basic-color-1) !important;
  }

  ::ng-deep ngx-siqueirasoft-nebular-formly-select, ngx-siqueirasoft-nebular-formly-select-icon {
    width: 100%;
  }

  // Tabela
  ::ng-deep table {
    width: 100%;
  }

  ::ng-deep .marginInput {
    margin: 0 30px;
  }

  ::ng-deep .bg_fundo {
    thead {
      background: nb-theme(border-basic-color-1);
    }

    th {
      color: nb-theme(border-basic-color-1) !important;
      font-family: "Nunito", sans-serif;
    }

    // td.cdk-column-description {
    // color: #5e5e5e;
    // }

    tr.mat-header-row,
    tr.mat-footer-row,
    tr.mat-row {
      height: 36px;
      background-color: nb-theme(background-basic-color-2);
    }

    .mat-cell,
    .mat-sort-header-content {
      color: nb-theme(text-basic-color);
    }

    button.edit_btn {
      border: none;
      background: transparent;
      font-size: 25px;
      cursor: pointer;
      width: 36px;
      padding-top: 6px;

      &:hover {
        opacity: 0.7;
      }
    }

    button.delete_btn {
      border: none;
      background: transparent;
      font-size: 25px;
      cursor: pointer;
      width: 36px;
      padding-top: 5px;

      &:hover {
        opacity: 0.7;
      }
    }

    button.download_btn {
      border: none;
      background: transparent;
      font-size: 25px;
      cursor: pointer;
      width: 36px;
      padding-top: 5px;

      &:hover {
        opacity: 0.7;
      }
    }

    .mat-sort-header-indicator {
      color: nb-theme(text-basic-color);
      top: 4px;
    }

    .mat-sort-header-stem {
      display: none;
    }
  }

  ::ng-deep .section_input {
    width: 100%;
    display: flex;
    margin: 15px 0 0 0;
    .table_input {
      font-family: "Nunito", sans-serif;
    }
  }

  ::ng-deep .btn_tables {
    display: inline-flex;
  }

  ::ng-deep .hide {
    display: none !important;
  }

  ::ng-deep .min-w-100 {
    min-width: 100% !important;
  }

  ::ng-deep .tables-all {
    background-color: nb-theme(border-basic-color-1) !important;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 9px;
  }

  //::ng-deep nb-layout-header ~ button {
  //  color: nb-theme(header-text-color) !important;
  //}
}
